import react, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setBalance } from '../redux/user.slice';
import {
  setJackpots,
  setRecentWinners,
  setRecentWithdraws,
} from '../redux/recent.slice';
import signalRSingleton from '../singletons/signalR.singleton';
import globalEmitter, { emits } from '../emitter/global.emitter';

function SocketWrapper() {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.user);

  const userHubConnected = useRef(false);
  const slotHubConnected = useRef(false);

  function watchUserHubEvents() {
    if (signalRSingleton.userHubConnected) {
      signalRSingleton.userHubConnection.on('Balance', (message) => {
        dispatch(setBalance(message));
      });
      signalRSingleton.userHubConnection.on('Ftd', (message) => {
        if (window?.gtag) {
          // console.log('FtD', {
          //   send_to: 'AW-11482617491/CPoMCLHdmLsZEJOtq-Mq',
          //   transaction_id: message,
          // });
          console.log('GetNotification from Socket', 'sent');
          window.gtag('event', 'conversion', {
            send_to: 'AW-11482617491/CPoMCLHdmLsZEJOtq-Mq',
            transaction_id: message,
          });
        }
      });
      signalRSingleton.userHubConnection.on('notification', (message) => {
        if (window?.gtag) {
          // console.log('notification', message);
          globalEmitter.emit(emits.ALERT, message);
        }
      });
    }
  }

  function watchSlotHubEvents() {
    if (signalRSingleton.slotHubConnected) {
      signalRSingleton.slotHubConnection.on(
        'RecentWinnersResult',
        (message) => {
          if (message && message.length) {
            dispatch(setRecentWinners(message));
          }
        }
      );

      signalRSingleton.slotHubConnection.on(
        'RecentWithdrawResult',
        (message) => {
          if (message && message.length) {
            dispatch(setRecentWithdraws(message));
          }
        }
      );

      signalRSingleton.slotHubConnection.on('Jackpot', (message) => {
        try {
          dispatch(setJackpots(JSON.parse(message)));
        } catch (e) {
          console.error('WrapperSocket -> Jackpot parse Error:', e);
        }
      });
    }
  }

  async function connectToUserHub() {
    try {
      await signalRSingleton.connectToUserHub();
      watchUserHubEvents();
    } catch (e) {
      console.error('UserHub Connection Error: ', e);
    }
  }

  async function connectToSlotHub() {
    try {
      await signalRSingleton.connectToSlotHub();
      watchSlotHubEvents();
    } catch (e) {
      console.error('SlotHub Connection Error: ', e);
    }
  }

  useEffect(() => {
    if (id && !userHubConnected.current) {
      connectToUserHub();
      userHubConnected.current = true;
    } else if (!id && userHubConnected.current) {
      signalRSingleton.disconnectFromUserHub();
      userHubConnected.current = false;
    }
  }, [id, userHubConnected.current]);

  useEffect(() => {
    if (!slotHubConnected.current) {
      connectToSlotHub();
      slotHubConnected.current = true;
    }
  }, []);

  return <></>;
}

export default react.memo(SocketWrapper);
